import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Link } from "gatsby"


const pricing = [
  {
    id : "Homepage2-Subs3Months-Button",
    title: "WW Subscription: 3 months",
    perks: [
      {boldText:"Unlimited access to 1:1 mentorship", regularText: " sessions during subscription"},
      {boldText:"Unlimited access to all live and past recordings", regularText: " of WW Masterclasses during your subscription"},
    ],
    price: "IDR 145K",
    buttonText: "Sign up now!",
    buttonLink: "https://forms.gle/HD6bwbarYqyEN6hf6",
    isSubscription: true,
    isRecommended: true,
  },
  {
    id : "Homepage2-Subs1Months-Button",
    title: "WW Subscription: 1 month",
    perks: [
      {boldText:"Unlimited access to 1:1 mentorship", regularText: " sessions during subscription"},
      {boldText:"Access to only 10 live or past recordings", regularText: " of WW Masterclasses during your subscription"},
    ],
    price:  "IDR 350K",
    buttonText: "Sign up now!",
    buttonLink: "https://forms.gle/HD6bwbarYqyEN6hf6",
    isSubscription: true,
    isRecommended: false,
  }
]


const IndexSection4Background = styled.div`
  background-color: ${props => props.theme.colors.white};
  @media(max-width:991px){
    min-height: 0;
    padding-bottom:50px;
  }
  @media(min-width:992px){
    padding-bottom:60px;
  }
`

const IndexSection4Title = styled.div`
  color: ${props => props.theme.colors.gray_dark};
  font: 58px/62px Abril Fatface;
  @media(max-width:991px){
    margin-top:50px;
  }
  @media(max-width:767px){
    margin-top:50px;
    font: 2.5rem/3rem Abril Fatface;
  }
`

const IndexSection4Text = styled.div`
  color: ${props => props.theme.colors.gray_dark};
  font: 19px/28px Roboto;
  margin-top:35px;
  margin-bottom:25px;
  @media(max-width:767px){
    margin-top:20px;
  }
  .row {
    margin-bottom:20px;
  }
  span {
    margin-top:20px;
    margin-left:7px;
  }
`

const PricingMentorshipCard = styled(Card)`
    height: 482px;
    position: relative;
    border: ${props=>props.isRecommended? "4px solid "+ props.theme.colors.primary_violet
    :"2px solid " + props.theme.colors.gray_shadow_card};
    box-shadow: 0px 1px 3px ${props=>props.theme.colors.gray_shadow_card};
    @media(max-width:767px){
        width: 96%;
        margin-right: auto;
        margin-left: auto;
        margin-top: 15px;
        &:hover, &:focus, &.focus, &:active, &.active  {
          margin-top:10px;
        }
    }
    @media(min-width:768px){
        width: ${props=>!props.isSubscription?`376px`:`278px`};
        margin-right: 16px;
        margin-top:55px;
        &:hover, &:focus, &.focus, &:active, &.active  {
          margin-top:50px;
        }
    }
`
const CardTitle = styled(Card.Title)`
  margin-bottom:30px;
  margin-top:18px;
  font: normal 28px/32px Roboto;
  color: ${props=>props.isSubscription? props.theme.colors.primary_violet : props.theme.colors.gray_dark};
`

const CardText = styled(Card.Text)`
    margin-bottom:20px;
`

const PriceTextFont = `
    color: ${props=>props.theme.colors.secondary_green10};
    border-bottom: 2px dotted ${props=>props.theme.colors.secondary_green10};`

const PriceText = styled.div`
    color: ${props=>props.theme.colors.secondary_green10};
    border-bottom: 2px dotted ${props=>props.theme.colors.secondary_green10};
    width: fit-content;
    position: absolute;
    margin-top: 10px;
    bottom: 115px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
`

const PriceHeader = styled.div`
    margin-top: 20px;
    margin-bottom: 5px;
    color: ${props => props.theme.colors.gray_light};
    font: normal normal bold 14px/14px Roboto;
`

const PricePackage = styled.div`
    color: ${props=>props.theme.colors.gray_dark};
    font: normal normal bold 16px/38px Roboto;
    @media(max-width:767px){
        font-size: 14px;
    }
`


const PricePackageIDR = styled.div`
    color: ${props => props.theme.colors.secondary_green10};
    font: normal normal normal 16px/38px Roboto;
    @media(max-width:767px){
        font-size: 14px;
    }
`

const ButtonContainer = styled.div`
    position: absolute;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    width: fit-content;
    @media(max-width:767px){
        bottom: 0.2px;
    }
    @media(min-width:768px){
        bottom: 40px;
    }
`

const ButtonFill = styled(Button)`
    height: 60px;
    color: ${props => props.theme
        .buttons[props.isRecommended? "primary_button_violet" : "secondary_big_button_violet"]
        .normal.color} !important;
    background-color: ${props => props.theme
        .buttons[props.isRecommended? "primary_button_violet" : "secondary_big_button_violet"]
        .normal.background_color} !important;
    border-color: ${props => props.theme
        .buttons[props.isRecommended? "primary_button_violet" : "secondary_big_button_violet"]
        .normal.border_color} !important;
    font: 22px/22px Roboto;
    transition: 0.25s ease-out;
    padding: 0px 20px 0px 20px;
    &:hover {
    color: ${props => props.theme
        .buttons[props.isRecommended? "primary_button_violet" : "secondary_big_button_violet"]
        .hovered.color} !important;
    background-color: ${props => props.theme
        .buttons[props.isRecommended? "primary_button_violet" : "secondary_big_button_violet"]
        .hovered.background_color} !important;
    border-color: ${props => props.theme
        .buttons[props.isRecommended? "primary_button_violet" : "secondary_big_button_violet"]
        .hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.colors.gray_shadow} !important;
    @media(min-width:1199px){
        margin-top:-3px;
        margin-bottom:3px;
    }
    }
    &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme
        .buttons[props.isRecommended? "primary_button_violet" : "secondary_big_button_violet"]
        .pressed.color} !important;
    background-color: ${props => props.theme
        .buttons[props.isRecommended? "primary_button_violet" : "secondary_big_button_violet"]
        .pressed.background_color} !important;
    border-color: ${props => props.theme
        .buttons[props.isRecommended? "primary_button_violet" : "secondary_big_button_violet"]
        .pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
        margin-top:-3px;
        margin-bottom:3px;
    }
    }
    @media(max-width:767px){
      width:100%;
      margin-bottom:20px;
      font: 16px/22px Roboto;
    }
`

const FACheck = styled(FontAwesomeIcon)`
  font-size:1rem;
  color: ${props => props.theme.colors.secondary_green10};
`

const RecommendedButton = styled(Button)`
    width: 180px;
    height: 32px;
    border-radius: 25px;
    background: linear-gradient(90deg, ${props=>props.theme.colors.secondary_green_gradient_light} 0%, 
        ${props=>props.theme.colors.secondary_green_gradient_dark} 100%)!important;
    font: normal normal bold 14px/14px Roboto;
    text-align: right;
    position: absolute;
    padding-right:20px;
    top: 25%;
    left: 48%;
`

const CrownCircle = styled(Button)`
    position: absolute;
    left: 0;
    top: 0;
    width: 72px;
    height: 72px;
    border-radius: 72px;
    background-color:white;
    box-shadow: 0px 1px 10px #00000029;
`

const CrownImg = styled.img`
    width: 42px;
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

const RecommendedContainer = styled(Container)`
    position: absolute;
    width: fit-content;
    top: -37px;
    @media(max-width:767px){
        left: 25%;
    }
`

const LinkText = styled(Link)`
  color: ${props=>props.theme.colors.primary_violet};
  text-decoration-line: underline;
`


function RecommendedCircle(isRecommended){
  if(isRecommended){
      return (
          <RecommendedContainer
          >
              <div css={css`position:relative;width:72px;height:72px;`}>
                  <RecommendedButton 
                  >
                      RECOMMENDED!
                  </RecommendedButton>
                  <CrownCircle/>
                  <CrownImg alt="WomenWorks - crown" src="/page-sign-up-for-mentorship-section8-img1.png"/>
              </div>
          </RecommendedContainer>
      )
  }
}


export default function Setion4() {
  return (
    <IndexSection4Background >
      <Container>
        <Row>
          <Col lg="9" xl="9" css={css`
          @media(min-width:992px){
            margin-top:95px;
          }`}>
            <IndexSection4Title>
              Enjoy WomenWorks anytime through subscription
            </IndexSection4Title>
            </Col>
            </Row>
        <Row>
          <Col lg="8" xl="8" css={css`
          @media(min-width:992px){
            margin-top:25px;
          }`}>
            <Row css={css `justify-content: center;`}>
              <img alt="WomenWorks App - Coming Soon" src="/page-index-app-coming-soon-img@2x.png"
                css={css`
                  @media(max-width:991px) {
                    max-height: 400px;
                    object-fit: cover;
                    margin-top: 10px;
                    margin-bottom: 20px;
                  }
                  @media(min-width:992px) {
                    display: none;
                  }
                `}></img>
            </Row>
            <IndexSection4Text>
              Find out more about our <LinkText
                to={`/sign-up-for-mentorship-coaching?open_faq=true#subscription_faq`}>subscription benefits here</LinkText>.
            </IndexSection4Text>
            
      <Row css={css`
      @media(min-width:992px) {
            justify-content: left;
      }
      @media(max-width:991px) {
        justify-content: center;
      }`}>
        {pricing.map((item, i) => 
            <Col xs="12" md="auto" css={css`
            padding: 0 0 0 0;
            @media(max-width:767px){
                padding-top: ${item.isRecommended? '15px':'0px'};
            }
            `}>
            <PricingMentorshipCard isSubscription={item.isSubscription}
            isRecommended={item.isRecommended}>

                {RecommendedCircle(item.isRecommended)}

                <Card.Body>
                    <CardTitle isSubscription={item.isSubscription}
                    >{item.title}</CardTitle>
                    <CardText>
                      {item.perks.map((perk)=>
                      
                    <Row  >
                        <Col xs="auto" css={css`padding-right:0;`}>
                            <FACheck icon={faCheck} />
                        </Col>
                        <Col css={css`padding-left:7px;`}>
                            <b>{perk.boldText}</b> {perk.regularText}
                        </Col>
                    </Row>
                      )}
                        {item.isSubscription?
                            <Row>
                                <div css={css`${PriceTextFont}`}><PriceText><span css={css`font-size:22px;line-height:32px;`}>{item.price}</span> per month</PriceText></div>
                            </Row>
                            :
                            <Row>
                                <Col xs="6">
                                <PriceHeader>Professional<br/>experience</PriceHeader>
                                    {item.price.map((p)=>
                                        <PricePackage>{p.name}<br/></PricePackage>
                                    )}
                                </Col>
                                <Col xs="6">
                                <PriceHeader css={css`margin-bottom:20px;`}>Price per person </PriceHeader>

                                {item.price.map((p)=>
                                    <PricePackageIDR><b>{p.priceBold}</b>{p.priceRegular}<br/></PricePackageIDR>
                                )}
                                </Col>
                            </Row>
                        }
                    <ButtonContainer>
                      <OutboundLink target="_blank" rel="noreferrer" href={item.buttonLink} id={item.id}>
                        <ButtonFill isRecommended={item.isRecommended}>
                          {item.buttonText}
                        </ButtonFill>
                      </OutboundLink>
                    </ButtonContainer>

                    </CardText>
                </Card.Body>
            </PricingMentorshipCard>
            </Col>
        )}
      </Row>
              </Col>
          <Col lg="4" xl="4">
            <img alt="WomenWorks App - Coming Soon" src="/page-index-app-coming-soon-img@2x.png"
              css={css`
                @media(max-width:991px) {
                  display:none;
                }
                @media(min-width:992px) {
                  position: absolute;
                  right: 0;
                  min-height: 330px;
                  max-height: 754px;
                  max-width: 376px;
                  object-fit: cover;
                  top: 104px;
                  left: 1vw;
                  z-index: 1;
                }
                @media(min-width:1199px) {
                  position: absolute;
                  right: 0;
                  min-height: 330px;
                  max-height: 754px;
                  max-width: 376px;
                  object-fit: cover;
                  top: 43px;
                  z-index: 1;
                }
              `}></img>
          </Col>
        </Row>
      </Container>
    </IndexSection4Background>
  )
}